<template>
  <div class="chartWrapper">
    <apexchart
      class="pChart"
      type="bar"
      :options="profitOptions"
      :series="profitData"
    ></apexchart>
  </div>
</template>

<script>
// @ is an alias to /src
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { formatNum } from "../helper/formatNumber";
export default {
  name: "ProfitChart",
  setup() {
    const store = useStore();
    const calculation = computed(() => store.getters.calculation);
    function setProfitData() {
      if (typeof calculation.value.profAnalysis != "undefined") {
        const profitValues = [];
        for (let i = 1; i < calculation.value.profAnalysis.length; i++) {
          profitValues[i - 1] =
            Math.round(calculation.value.profAnalysis[i].profit * 100) / 100;
        }
        profitData.value[0].data[0] = 0;
        for (let i = 1; i < calculation.value.profAnalysis.length; i++) {
          if (profitData.value[0].data[i - 1] < 0 && profitValues[i] > 0) {
            profitData.value[0].data[i] =
              Math.round(
                (profitData.value[0].data[i - 1] + profitValues[i - 1]) * 100
              ) / 100;
          } else {
            profitData.value[0].data[i] =
              Math.round(
                (profitValues[i - 1] + profitData.value[0].data[i - 1]) * 100
              ) / 100;
          }
        }
        profitData.value[0].data = profitData.value[0].data.filter((e) => {
          return !isNaN(e) && e != 0;
        });
        store.dispatch("setProfitData", profitData.value[0].data);
      }
    }
    const profitData = ref([
      {
        name: "Profit accum.",
        data: [500000, 500000, 500000, 500000],
      },
    ]);
    const profitOptions = {
      chart: {
        type: "bar",
        forceNiceScale: true,
        toolbar: {
          show: true,
          offsetX: -5,
          offsetY: 5,
        },
      },
      title: {
        text: "Profit",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.7,
        },
      },
      xaxis: {
        title: {
          text: "Year",
        },
      },
      yaxis: {
        title: {
          text: "Profit",
        },
        labels: {
          formatter: formatNum,
        },
      },
      colors: [
        function ({ value }) {
          if (value < 0) {
            return "#FA8072";
          } else {
            return "#00f0e5";
          }
        },
      ],
      legend: {
        position: "top",
        horizontalAlign: "right",
        floating: true,
        offsetY: -10,
        offsetX: -5,
      },
      fill: {
        opacity: 1,
      },
    };
    setProfitData();
    watch(calculation, () => {
      setProfitData();
    });
    return {
      profitData,
      profitOptions,
    };
  },
};
</script>

<style></style>
