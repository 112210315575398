<template>
  <div class="divTable greenTable">
    <div class="divTableHeading divTableHeading-V-Table">
      <div class="divTableRow--dashboard">
        <div class="divTableHead">Implantation</div>
        <div class="divTableHead">Forest height</div>
        <div class="divTableHead">turbine model</div>
        <div class="divTableHead">No. of Turbines</div>
        <div class="divTableHead">Roughness length</div>
        <div class="divTableHead">power</div>
        <div class="divTableHead">hub height</div>
        <div class="divTableHead">rotor diameter</div>
      </div>
    </div>
    <div class="divTableBody">
      <div class="divTableRow divTableRow--V-Table-dashboard">
        <div class="divTableIDS">
          {{
            formatNum(
              Math.round(
                this.$store.getters.calculation.dashboard?.technical
                  .implantation * 100
              ) / 100
            )
          }}m
        </div>
        <div class="divTableIDS">
          {{
            formatNum(
              this.$store.getters.calculation.dashboard?.technical.forestHeight
            )
          }}m
        </div>
        <div class="divTableIDS">
          {{
            this.$store.getters.calculation.dashboard?.technical.turbineModel
          }}
        </div>
        <div class="divTableIDS">
          {{
            formatNum(
              this.$store.getters.calculation.dashboard?.technical.noOfTurbine
            )
          }}
        </div>
        <div class="divTableIDS">
          {{
            formatNum(
              Math.round(
                this.$store.getters.calculation.dashboard?.technical
                  .roughnessLength * 100
              ) / 100
            )
          }}
        </div>
        <div class="divTableIDS">
          {{
            formatNum(
              this.$store.getters.calculation.dashboard?.technical.power
            )
          }}kW
        </div>
        <div class="divTableIDS">
          {{
            formatNum(
              this.$store.getters.calculation.dashboard?.technical.hubHeight
            )
          }}m
        </div>
        <div class="divTableIDS">
          {{
            formatNum(
              this.$store.getters.calculation.dashboard?.technical.rotorDiameter
            )
          }}m
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatNum } from "../helper/formatNumber";

export default {
  setup() {
    return {
      formatNum,
    };
  },
};
</script>

<style></style>
