<template>
  <div class="chartWrapper">
    <apexchart
      class="pChart"
      type="bar"
      :options="options"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { formatNum } from "../helper/formatNumber";

export default {
  name: "InterestChart",
  setup() {
    const store = useStore();
    const calculation = computed(() => store.getters.calculation);
    const options = {
      chart: {
        type: "bar",
        toolbar: {
          show: true,
          offsetX: -5,
          offsetY: 5,
        },
      },
      tooltip: {
        x: {
          formatter: function (val) {
            return "Year " + val;
          },
        },
      },

      fill: {
        opacity: 1,
      },
      colors: ["#00f0e5", "#008764", "#00bae3"],
      title: {
        text: "Interest Paid",
        align: "left",
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.7,
        },
      },
      xaxis: {
        title: {
          text: "Year",
        },
      },
      yaxis: {
        title: {
          text: "Interest",
        },
        labels: {
          formatter: formatNum,
        },
        min: 0,
        forceNiceScale: true,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        floating: true,
        offsetY: -10,
        offsetX: -5,
      },
    };
    const series = ref([
      {
        name: "interest",
        data: [0, 0, 0, 0],
      },
    ]);
    function setInterestPayed() {
      if (typeof calculation.value.profAnalysis != "undefined") {
        for (let i = 1; i < calculation.value.profAnalysis.length; i++) {
          series.value[0].data[i - 1] =
            Math.round(calculation.value.profAnalysis[i].zinslast * 100) / 100;
        }
      }
    }
    setInterestPayed();
    watch(calculation, () => {
      setInterestPayed();
    });
    return {
      series,
      options,
    };
  },
};
</script>

<style></style>
