<template>
  <div class="divTable greenTable">
    <div class="divTableHeading divTableHeading-V-Table">
      <div class="divTableRow--dashboard">
        <div class="divTableHead">(P-Scenario)</div>
        <div class="divTableHead">Equity</div>
        <div class="divTableHead">Loan</div>
        <div class="divTableHead">Interest rate</div>
        <div class="divTableHead">CAPEX p. KW</div>
        <div class="divTableHead">Feed-In tarif</div>
        <div class="divTableHead">Revenue p.a.</div>
        <div class="divTableHead">Profit</div>
      </div>
    </div>
    <div class="divTableBody">
      <div class="divTableRow divTableRow--V-Table-dashboard">
        <div class="divTableIDS">
          {{ this.$store.getters.calculation.dashboard?.financial.pSzenario }}
        </div>
        <div class="divTableIDS">
          {{
            formatNum(
              Math.round(
                this.$store.getters.calculation.dashboard?.financial.equity *
                  100
              )
            )
          }}%
        </div>
        <div class="divTableIDS">
          {{ this.$store.getters.calculation.dashboard?.financial.loan * 100 }}%
        </div>
        <div class="divTableIDS">
          {{
            formatNum(
              Math.round(
                this.$store.getters.calculation.dashboard?.financial.interest *
                  10000
              ) / 100
            )
          }}%
        </div>
        <div class="divTableIDS">
          {{ this.$store.getters.calculation.dashboard?.financial.CAPEXpKW }}€
        </div>
        <div class="divTableIDS">
          {{
            formatNum(
              Math.round(
                this.$store.getters.calculation.dashboard?.financial
                  .feedInTarif * 1000
              ) / 1000
            )
          }}€
        </div>
        <div class="divTableIDS">
          {{
            formatNum(
              Math.round(
                this.$store.getters.calculation.dashboard?.financial.revenuePA *
                  100
              ) / 100
            )
          }}€
        </div>
        <div class="divTableIDS">
          {{
            formatNum(
              this.$store.getters.profitData[
                this.$store.getters.profitData.length - 1
              ]
            )
          }}€
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatNum } from "../helper/formatNumber";

export default {
  setup() {
    return {
      formatNum,
    };
  },
};
</script>

<style></style>
