<template>
  <div class="sectionContainer">
    <h1 class="subheader subheader--small">
      {{ this.$store.getters.calculation.dashboard?.projectName }}
    </h1>
    <div class="dashboardSecondContainer">
      <div class="chartTableBox1">
        <DashboardTechnicalTable></DashboardTechnicalTable>
        <div class="chartContainer">
          <MeasuredWindSpeedChart></MeasuredWindSpeedChart>
          <MonthlyOutputChartDashBoard></MonthlyOutputChartDashBoard>
        </div>
      </div>
      <div class="chartTableBox2">
        <DashboardFinancialTable></DashboardFinancialTable>
        <div class="chartContainer">
          <ProfitChart></ProfitChart>
          <InterestChart></InterestChart>
        </div>
      </div>
      <div class="dashBoardInfoBox1">
        <div class="infoBox infoBox1">
          <span class="dashBoardInfoBox-title">m/s avg.</span>
          <span class="dashBoardInfoBox-value">
            {{
              formatNum(
                Math.round(
                  this.$store.getters.calculation.dashboard?.cards.windspeed *
                    1000
                ) / 1000
              )
            }}
          </span>
        </div>
        <div class="infoBox infoBox2">
          <span class="dashBoardInfoBox-title">Production p.a</span>
          <span class="dashBoardInfoBox-value">
            {{
              formatNum(
                Math.round(
                  this.$store.getters.calculation.dashboard?.cards
                    .productionPA * 100
                ) / 100
              )
            }}
            kWh
          </span>
        </div>
        <div class="infoBox infoBox3">
          <span class="dashBoardInfoBox-title">Capacity factor</span>
          <span class="dashBoardInfoBox-value">
            {{
              formatNum(
                Math.round(
                  (this.$store.getters.calculation.dashboard?.cards
                    .fullLoadHours /
                    8760) *
                    10000
                ) / 100
              )
            }}%
          </span>
        </div>
        <div class="infoBox infoBox4">
          <span class="dashBoardInfoBox-title">Full load hours</span>
          <span class="dashBoardInfoBox-value">
            {{
              formatNum(
                Math.round(
                  this.$store.getters.calculation.dashboard?.cards
                    .fullLoadHours * 100
                ) / 100
              )
            }}
          </span>
        </div>
        <div class="infoBox infoBox5">
          <a
            :href="`https://maps.google.com/?q=${this.$store.getters.calculation.dashboard?.cards.lat},${this.$store.getters.calculation.dashboard?.cards.lng}`"
            target="_blank"
          >
            <span class="dashBoardInfoBox-title">lat/lng</span>
            <span class="dashBoardInfoBox-value">
              {{
                Math.round(
                  this.$store.getters.calculation.dashboard?.cards.lat * 1000
                ) / 1000
              }}/{{
                Math.round(
                  this.$store.getters.calculation.dashboard?.cards.lng * 1000
                ) / 1000
              }}
            </span>
          </a>
        </div>
      </div>
      <div class="dashBoardInfoBox2">
        <div class="infoBox infoBox1">
          <span class="dashBoardInfoBox-title">Equity tot</span>
          <span class="dashBoardInfoBox-value">
            {{
              formatNum(
                Math.round(
                  this.$store.getters.calculation.dashboard?.cards.equityTotal *
                    100
                ) / 100
              )
            }}€
          </span>
        </div>
        <div class="infoBox infoBox2">
          <span class="dashBoardInfoBox-title">Loan tot</span>
          <span class="dashBoardInfoBox-value">
            {{
              formatNum(
                Math.round(
                  this.$store.getters.calculation.dashboard?.cards.loanTotal *
                    100
                ) / 100
              )
            }}€
          </span>
        </div>
        <div class="infoBox infoBox3">
          <span class="dashBoardInfoBox-title">DSCR</span>
          <span class="dashBoardInfoBox-value">
            {{
              formatNum(
                Math.round(
                  this.$store.getters.calculation.dashboard?.cards.dscr * 100
                ) / 100
              )
            }}
          </span>
        </div>
        <div class="infoBox infoBox4">
          <span class="dashBoardInfoBox-title">OPEX p.a</span>
          <span class="dashBoardInfoBox-value">
            {{
              formatNum(
                Math.round(
                  this.$store.getters.calculation.dashboard?.cards.opexPA * 100
                ) / 100
              )
            }}€
          </span>
        </div>
        <div class="infoBox infoBox5">
          <span class="dashBoardInfoBox-title">CAPEX tot</span>
          <span class="dashBoardInfoBox-value">
            {{
              formatNum(
                Math.round(
                  this.$store.getters.calculation.dashboard?.cards.CapexTotal *
                    100
                ) / 100
              )
            }}€
          </span>
        </div>
        <div class="infoBox infoBox6">
          <span class="dashBoardInfoBox-title">RoE</span>
          <span class="dashBoardInfoBox-value">
            {{
              formatNum(
                Math.round(
                  (this.$store.getters.profitData[
                    this.$store.getters.profitData.length - 1
                  ] /
                    this.$store.getters.calculation.dashboard?.cards
                      .equityTotal) *
                    10000
                ) / 100
              )
            }}
            %
          </span>
        </div>
        <div class="infoBox infoBox7">
          <span class="dashBoardInfoBox-title">LCOE</span>
          <span class="dashBoardInfoBox-value">
            {{
              formatNum(
                Math.round(
                  this.$store.getters.calculation.dashboard?.cards.lcoe * 10000
                ) / 10000
              )
            }}€
          </span>
        </div>
      </div>
    </div>
    <div class="windrose-section windrose-section--dashboard">
      <img :src="windRoseImg" v-if="windRoseImg" />
      <button
        class="button--primary"
        @click="getWindrose"
        :disabled="gettingWindrose || windRoseImg"
        v-if="!windRoseImg"
      >
        Get windrose
      </button>
      <div class="tooltipPosition">
        <button class="tooltipInfo" data-tooltip="I’m the tooltip text.">
          i
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardTechnicalTable from "@/components/DashboardTechnicalTable.vue";
import DashboardFinancialTable from "@/components/DashboardFinancialTable.vue";
import InterestChart from "@/components/InterestChart.vue";
import MeasuredWindSpeedChart from "@/components/MeasuredWindSpeedChart.vue";
import ProfitChart from "@/components/ProfitChart.vue";
import MonthlyOutputChartDashBoard from "@/components/MonthlyOutputChartDashBoard.vue";
import { formatNum } from "../helper/formatNumber";
const ServerURL = process.env.VUE_APP_ENV_SERVER || "";
import axios from "axios";
import { useStore } from "vuex";
import { computed, ref } from "vue";

export default {
  name: "Dashboard",
  components: {
    DashboardTechnicalTable,
    DashboardFinancialTable,
    InterestChart,
    MeasuredWindSpeedChart,
    ProfitChart,
    MonthlyOutputChartDashBoard,
  },
  setup() {
    const store = useStore();
    const calculation = computed(() => store.getters.calculation);
    const windRoseImg = ref(null);
    const gettingWindrose = ref(false);
    function getWindrose() {
      gettingWindrose.value = true;
      axios
        .post(ServerURL + "/getWindRose", {
          ws: calculation.value.windspeedCalculator.windRose.ws,
          wd: calculation.value.windspeedCalculator.windRose.wd,
        })
        .then((response) => response.data)
        .then((returnValue) => {
          gettingWindrose.value = false;
          windRoseImg.value = "data:image/jpeg;base64," + returnValue.windRose;
        })
        .catch((err) => {
          console.log(err);
          gettingWindrose.value = false;
        });
    }
    return {
      getWindrose,
      windRoseImg,
      gettingWindrose,
      formatNum,
    };
  },
};
</script>
